import { Observable, of as observableOf } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DATATABLE_TYPE } from 'state-domains/constants';

import {
    buildConfigurationReorderHeaderFieldsUrl,
    buildConfigurationReorderSampleHeaderFieldsUrl,
} from '../../../network/urls';
import { sendRequestWithXSRFToken } from '../../../utils';

export const reorderHeaderFields = (
    headerId: string,
    sectionIds: string[],
    payload: any,
    multiSection = false,
    configType: DATATABLE_TYPE,
): Observable<any> => {
    const url =
        configType === DATATABLE_TYPE.HEADERS
            ? buildConfigurationReorderHeaderFieldsUrl(headerId, sectionIds, multiSection)
            : buildConfigurationReorderSampleHeaderFieldsUrl(headerId, sectionIds, multiSection);
    return sendRequestWithXSRFToken(url, payload, 'PUT').pipe(switchMap(() => observableOf({})));
};
